import React, { useCallback, useState } from 'react';

import Modal from '../Modal';

import { section, inner, title, link, description } from './styles.module.scss';

const Explore = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  return (
    <>
      <section className={section}>
        <div className="ocx-media-wrap">
          <div className={inner}>
            <p className={title}>
              Explore additional photos of yourself and other stars of the
              night.
            </p>

            <button
              onClick={handleOpenModal}
              type="button"
              className={link}
              id="explore-all-photos"
            >
              Explore all photos <span> ↗</span>
            </button>

            {/* <p className={description}>13GB of photos on Dropbox</p> */}
          </div>
        </div>
      </section>

      {isOpen ? <Modal closeModal={handleOpenModal} /> : <></>}
    </>
  );
};

export default Explore;

import React from 'react';
import { Helmet } from 'react-helmet';

import Header from 'sections/ScreverOcxMedia/Header';
import Hero from 'sections/ScreverOcxMedia/Hero';
import Gallery from 'sections/ScreverOcxMedia/Gallery';
import Explore from 'sections/ScreverOcxMedia/Explore';
import Footer from 'sections/ScreverOcxProgram/Footer';
import Survey from 'sections/ScreverOcxMedia/Survey';

import 'sections/ScreverOcxMedia/common.scss';

const OcxEventMedia = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver OCX Event | Photos"
      >
        <meta
          name="description"
          content="View photos from the Screver OCX event."
        />
        <meta name="theme-color" content="#151517"></meta>
        <meta name="robots" content="noindex, nofollow, nosnippet"></meta>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-event-media">
        <Header />

        <main>
          <Hero />
          <Gallery />
          <Explore />
          <Survey />
        </main>

        <div className="ocx-footer-wrap ocx-media-wrap">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default OcxEventMedia;

import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

// import Icon from 'components/UI/Icon';
import SpecialLink from 'sections/ScreverOcxProgram/Footer/SpecialLink';

import {
  section,
  inner,
  title,
  description,
  counterStyle,
  link,
} from './styles.module.scss';

const Modal = ({ closeModal }) => {
  const [counter, setCounter] = useState(3);
  const [showLink, setShowLink] = useState(false);
  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      if (counter > 0) {
        setCounter((state) => state - 1);
      } else {
        clearInterval(interval.current);
        setShowLink((state) => !state);
      }
    }, 1000);

    return () => clearInterval(interval.current);
  }, [counter]);

  return (
    <div
      className={classnames(section, 'ocx-media-modal')}
      onClick={closeModal}
      tabIndex={0}
      role="button"
    >
      <div className={inner}>
        <p className={title}>
          {!showLink ? (
            <>
              We are preparing image gallery for you <br />
              <span className={counterStyle}>
                <span>{counter}</span>...
              </span>
            </>
          ) : (
            <>Go to the gallery</>
          )}
        </p>

        {showLink ? (
          // <a
          //   href="https://www.dropbox.com/sh/lhyg456zdgxbqcg/AAC-7oVmReE2BLX9ZUtTgdCSa?dl=0"
          //   target="_blank"
          //   rel="noopener noreferrer"
          //   className={link}
          //   id="modal-link-to-dropbox"
          // >
          //   <Icon name="dropbox" />
          //   Proceed to photo archive
          // </a>
          <Link
            to="/events/screver-ocx/gallery/"
            className={link}
            id="modal-link-to-gallery"
          >
            Proceed to photo archive
          </Link>
        ) : (
          <></>
        )}
        <p className={description}>
          but meanwhile let's connect on LinkedIn 😉
        </p>
        <SpecialLink id="modal-link-to-linkedIn" />
      </div>
    </div>
  );
};

export default Modal;
